// Odpowiedzi
// 8 5 3
// 2 9 7
// 18 10 6
// 3 7 3
// 12 1 7
// 19 11 1
// 3 11 15

<template>
  <div id="game-2">
    <header>
      <!-- <button @click="isGameStart = false" v-if="isGameStart">MENU</button> -->
      <div class="back-btn">
        <router-link to="/games">
          <i class="fas fa-chevron-left white-text"></i>
          <label>WYJDŹ</label>
        </router-link>

        <!-- Witaj {{ user.displayName }} -->
      </div>
      <div>
        <span v-if="isGameStart">{{ userTimer }}</span>
        <i v-if="allMissionsComplete == true" style="margin-left: 8px" @click="showHelpScreen = !showHelpScreen" class="fas fa-question-circle"></i>
      </div>
    </header>

    <div id="intro-screen" class="content" v-if="!isGameStart">
      <div class="intro-pikto">
        <img src="/game2/timer.png" alt="Czas gry">
        <img src="/game2/age.png" alt="Wiek gracza">
      </div>

      <div class="intro-text">
        <p>
          Wyrusz śladami dawnych mieszkańców Karpacza w miejsca, w których żyli, pracowali, lub takie, które są w inny sposób z nimi związane. Twoim zadaniem jest odczytanie przesłania, które po sobie pozostawili.
        </p>
      </div>

      <button v-if="selectedMission == -1" @click="startGame()">Start</button>
      <button v-else @click="returnGame()">Wznów</button>
    </div>
    
    <div class="menu-screen content" v-if="isGameStart && !isSelectedMission && !isRoseOpen">
      
      <div class="menu-missions">
        <p>Wybierz miejsce oznaczone odpowiednią parą znaków</p>
        <div>
          <div><button style="background-image:url('/game2/stones/1+2.png');" @click="selectMission(0)" :active="missionIsDisabled(0)"></button></div> <div></div> <div></div>
          <div></div> <div><button style="background-image:url('/game2/stones/3+4.png');" @click="selectMission(1)" :active="missionIsDisabled(1)"></button></div> <div></div>
          <div></div> <div></div> <div><button style="background-image:url('/game2/stones/5+6.png');" @click="selectMission(2)" :active="missionIsDisabled(2)"></button></div>
          <div></div> <div><button style="background-image:url('/game2/stones/7+8.png');" @click="selectMission(3)" :active="missionIsDisabled(3)"></button></div> <div></div>
          <div><button style="background-image:url('/game2/stones/9+10.png');" @click="selectMission(4)" :active="missionIsDisabled(4)"></button></div> <div></div> <div></div>
          <div></div> <div><button style="background-image:url('/game2/stones/11+12.png');" @click="selectMission(5)" :active="missionIsDisabled(5)"></button></div> <div></div>
          <div></div> <div></div> <div><button style="background-image:url('/game2/stones/13+14.png');" @click="selectMission(6)" :active="missionIsDisabled(6)"></button></div>
          <!-- <div v-for="(mission, index) in missions" :key="index">
            <button @click="selectMission(index)" :disabled="missionIsDisabled(index)">{{ mission.name }}</button>
          </div> -->
        </div>
      </div>

      <div>
        <button @click="isRoseOpen = true" class="rose-btn" :disabled="allMissionsComplete == false">Róża Wiatrów</button>
        <!-- <div v-if="gameComplete">{{ secretPassword }}</div> -->
      </div>

    </div>

    <div class="game-screen content" v-if="isSelectedMission && !isRoseOpen">
      <!-- <h1>{{ missions[selectedMission].name }}</h1> -->

      <div class="progress-bar">
        <img v-for="(question, index) in missions[selectedMission].questions" :key="index" :src="`/game2/stones/${selectedMission*2+index+1}.png`" :class="{'active-item': index == currentQuestion}" >
      </div>

      <div class="card">
        <div v-if="currentQuestion == 0">
          <h4>{{ missions[selectedMission].name }}</h4>
          <p style="margin-top:1rem;">{{ missions[selectedMission].questions[currentQuestion].question }}</p>
        </div>
        
        <div v-else>
          <h4>Rozejrzyj się i wybierz prawidłowo</h4>
          <div style="display:grid;grid-template: auto / 1fr 1fr;gap:1rem;justify-content: center;">
            <div v-for="(photo, index) in missions[selectedMission].questions[currentQuestion].photos" :key="index">
              <img :src="photo.img">
              <label>{{ photo.number }}</label>
            </div>
          </div>
          
        </div>
      </div>

      <div >
        <div class="rose-screen-btns">
          <button @click="prevQuestion()" style="background-image:url('/game2/btn_prev.png');width:70px;height:70px;" :disabled="currentQuestion != missions[selectedMission].questions.length-1"></button>
          <button @click="backToMap()">Mapa</button>
          <button @click="nextQuestion()" v-if="currentQuestion != missions[selectedMission].questions.length-1" style="background-image:url('/game2/btn_next.png');width:70px;height:70px;"></button>
          <button @click="winMission()" v-else style="background-image:url('/game2/btn_done.png');width:70px;height:70px;"></button>
        </div>
      </div>
      
    </div>

    <div class="rose-screen content" v-if="isRoseOpen">
      <div></div>
      <p>Klikając w odpowiednie przyciski utworzysz hasło główne</p>
      <div class="rose-grid">
        <!-- Tutaj za pomocą grida zrobię siatkę na końcu które będa przycisk z literami, po kliknięciu w daną literę (zbiór liter) ma on pojawić się na dole dopisany do hasła głównego -->
        <button @click="addToPassword(btn)" v-for="(btn, index) in roseButtons" :key="index">{{ btn }}</button>

        <img class="rose" src="/game2/rose.png" style="margin:0;">
      </div>

      <div>
        HASŁO:
        <div v-if="gameComplete" class="secret-password password-pass">{{ secretPassword }}</div>
        <div v-else :class="{'secret-password': true, 'password-pass': gameComplete, 'password-wrong': wrongPassword}">{{ password }}</div>
        <!-- <div class="placeholder-password"><span v-for="(char) in secretPassword.length" :key="char">_</span></div> -->
      </div>
      
      <div v-if="!gameComplete" class="rose-screen-btns">
        <button @click="resetPassword()" :disabled="password == ''">USUŃ</button>
        <button @click="isRoseOpen = false">MAPA</button>
      </div>
      <div v-else class="rose-screen-btns">
        <button @click="$router.push('/games');">Wyjdź</button>
      </div>
    </div>

    <!-- <footer>
        {{ instructionArray }}
    </footer> -->

    <help-screen v-if="showHelpScreen" :pages="helpPages"></help-screen>
  </div>
</template>

<script>
/*
Zagadka 2
W .help-screen należy umieścić instrukcję co do róży wiatrów, powinna się ona uruchomić na start czyli: showHelpScreen można ustawić na true

Gra polega na wybraniu lokalizacji/adresu z listy .menu-screen.

.game-screen
Po wybraniu miejsca należy się w nie udać i odpowiedzieć na 2 pytania.
Na pierwsze odpowiedź jest zawsze LEWO lub PRAWO
Na drugie zawsze cyfra 1-9
Efektem odpowiedzi jest uzyskanie kierunku oraz wartości poruszania się po Róży wiatrów np. LEWO 3 - idź w lewo o 3

Po odpowiedzi na wszystkie pytania pojawia się widok .rose-screen z interaktywną Różą wiatrów gdzie korzystając z uzyskanych odpowiedzi rozwiązujemy łamigłówkę i poznajemy główne hasło. Róża wiatrów na każdym swoim końcu ma po kilka liter które po złączeniu utworzą główne hasło.

? Na każdym widoku (.rose-screen, .menu-screen, .game-screen) powinien być widoczny pasek z aktualnym stanem istrukcji do odkodowania
np.
------------------------------
| 1. LEWO 3 / 2. PRAWO 8 ... |
------------------------------
Tak aby podczas odpowiedzi na pytania było widać co dostajemy za prawidłową odpowiedź.
W menu widzieć postęp
W widoku z Różą widzieć kolejne instrukcje do odkodowania
*/
import HelpScreen from '@/components/HelpScreen';
import firebase from '@/firebase';

export default {
  components: {
    HelpScreen
  },
  data() {
    return {
      showHelpScreen: false,
      helpPages: [
        {
          title: "Odpowiedzi",
          content: "Uważaj na następnej stronie okna pomocy znajduje się podpowiedź do gry pozwalająca Ci ukończyć zadanie"
        },
        {
          title: "Odpowiedzi",
          content: "",
          imgUrl: "/game2/game2_odp.jpg"
        },
        {
          title: "Okno pomocy",
          content: "Aby zamknąć okno pomocy kliknij ponownie w przycisk oznaczony symbolem znaku zapytania (?)"
        },
      ],
      isSelectedMission: false,
      isGameStart: false,
      isRoseOpen: false,
      selectedMission: -1,
      currentQuestion: 0,
      instructionArray: [], // Tablica z instrukcjami do rozwiązania róży wiatrów
      allMissionsComplete: false,
      roseButtons: ["AD", "KI", "YW", "N", "IE", "EM", "ST", "OP", "AM", "EĆ", "ED", "W", "E", "AJ", "PR", "NI", "YP", "ST", "MN", "NI", "ZE", "PO", "ZA", "YI"],
      password: "",
      selectedLettersCounter: [],
      secretPassword: "PRZESTAJEMY ISTNIEĆ KIEDY POPADAMY W ZAPOMNIENIE",
      gameComplete: false,
      wrongPassword: false,
      userTimer: "00:00:00",
      startDateTime: null,
      missions: [
        {
          name: "Przewodnik",
          isComplete: false,
          questions: [
            {
              question: "Zdobycie szczytu nazywanego „dachem świata” jest marzeniem wszystkich wspinaczy. Ludzi od zawsze kusi perspektywa zdobycia „góry gór”, stąd niekończące się wyprawy na najwyższy z ośmiotysięczników. Zapisz brakującą cyfrę w wysokości tej góry: 8X48",
            },
            {
              question: "Rozejrzyj się i wybierz prawidłowo",
              photos: [
                {
                  img: "/game2/imgs/1a.jpg",
                  number: 5
                },
                {
                  img: "/game2/imgs/1b.jpg",
                  number: 7
                },
                {
                  img: "/game2/imgs/1c.jpg",
                  number: 10
                },
                {
                  img: "/game2/imgs/1d.jpg",
                  number: 2
                }
              ]
            },
          ]
        },
        {
          name: "Sędzia",
          isComplete: false,
          questions: [
            {
              question: "Na jednej ze ścian tego budynku, łatwo dostrzeżesz płaskorzeźby przedstawiające dawnych panów. Jeżeli już wiesz o kim mowa, to dokładnie policz wszystkie szyby w oknach umieszczonych na ich wysokości. Wynik podziel przez liczbę wszystkich postaci tam ukazanych. Wartość zapisz na karcie.",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/2a.jpg",
                  number: 9
                },
                {
                  img: "/game2/imgs/2b.jpg",
                  number: 8
                },
                {
                  img: "/game2/imgs/2c.jpg",
                  number: 5
                },
                {
                  img: "/game2/imgs/2d.jpg",
                  number: 2
                }
              ]
            },
          ]
        },
        {
          name: "Pastor",
          isComplete: false,
          questions: [
            {
              question: "Idź w kierunku tej części świątyni, która zwrócona jest w stronę ulicy. Jeśli jesteś spostrzegawczy bez trudy zauważysz kwiaty na murze usłane. Policz te po prawej i po lewej stronie. Sumę kwiatów na karcie zapisz. Jeśli nie wiesz gdzie to jest, wypatruj początku i końca alfabetu greckiego. On kierunek Ci wskaże.",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/3a.jpg",
                  number: 10
                },
                {
                  img: "/game2/imgs/3b.jpg",
                  number: 1
                },
                {
                  img: "/game2/imgs/3c.jpg",
                  number: 5
                },
                {
                  img: "/game2/imgs/3d.jpg",
                  number: 16
                }
              ]
            },
          ]
        },
        {
          name: "Laborant",
          isComplete: false,
          questions: [
            {
              question: "Przyjrzyj się uważnie domostwu, które ciekawy wątek sudeckich alchemików z Karpacza i okolic przypomina. Dom ten przebudowany, kształt swój zachował, ale o lukarny się wzbogacił. Policz te na drogę skierowane i wynik zapisz na karcie.",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/4a.jpg",
                  number: 7
                },
                {
                  img: "/game2/imgs/4b.jpg",
                  number: 9
                },
                {
                  img: "/game2/imgs/4c.jpg",
                  number: 11
                },
                {
                  img: "/game2/imgs/4d.jpg",
                  number: 5
                }
              ]
            },
          ]
        },
        {
          name: "Listonosz",
          isComplete: false,
          questions: [
            {
              question: "Kolejną zagadkę przyjdzie Ci rozwiązać obchodząc zabytkowy dom przysłupowy. To tutaj urządzono muzeum, w którym znajduje się popiersie najsłynniejszego pocztowca. Gdy okrążysz budynek wszystkie pięknie zdobione okiennice dostrzeżesz. Policz ile zamykają okien i wynik zapisz na karcie.",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/5a.jpg",
                  number: 1
                },
                {
                  img: "/game2/imgs/5b.jpg",
                  number: 2
                },
                {
                  img: "/game2/imgs/5c.jpg",
                  number: 7
                },
                {
                  img: "/game2/imgs/5d.jpg",
                  number: 4
                }
              ]
            },
          ]
        },
        {
          name: "Walończyk",
          isComplete: false,
          questions: [
            {
              question: "Izajasz 55.12 ??88. ZAJĄC. R+D",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/6a.jpg",
                  number: 11
                },
                {
                  img: "/game2/imgs/6b.jpg",
                  number: 15
                },
                {
                  img: "/game2/imgs/6c.jpg",
                  number: 13
                },
                {
                  img: "/game2/imgs/6d.jpg",
                  number: 12
                }
              ]
            },
          ]
        },
        {
          name: "Artysta",
          isComplete: false,
          questions: [
            {
              question: "Znajdź niezwykłych aktorów, którzy przez cały rok przedstawienia odgrywają. Nawet na zimę pozostają by mieć kontakt z publicznością nieprzerwany. Przypatrz się uważnie i liczbę wykonawców na karcie zapisz.",
            },
            {
              question: "Pytanie 2",
              photos: [
                {
                  img: "/game2/imgs/7a.jpg",
                  number: 11
                },
                {
                  img: "/game2/imgs/7b.jpg",
                  number: 15
                },
                {
                  img: "/game2/imgs/7c.jpg",
                  number: 2
                },
                {
                  img: "/game2/imgs/7d.jpg",
                  number: 8
                }
              ]
            },
          ]
        },
      ]
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user =>  {
      if (user) {
        this.$store.commit('SET_USER', user);
        this.getUserData();
        this.shufflePhotos();
      } else {
        this.$router.push('/');
      }
    });
  },
  methods: {
    async getUserData() {
      const db = firebase.firestore();
      const ref = db.collection('users').doc(this.$store.state.currentUser.uid);
      const doc = await ref.get();
      if (!doc.exists) {
        console.log('No such document!');
        this.$router.push("/games");
      } else {
        console.log(doc.data());
        if (doc.data().games.game2.active == false || doc.data().games.game2.gameComplete == true) {
          this.$router.push('/games');
        }
        if (doc.data().games.game2.missions) {
          console.log("Restore from save");
          this.instructionArray = doc.data().games.game2.instructionArray;
          if (doc.data().games.game2.allMissionsComplete) {
            this.allMissionsComplete = doc.data().games.game2.allMissionsComplete;
          }
          if (doc.data().games.game2.startDateTime) {
            this.startDateTime = doc.data().games.game2.startDateTime;
          }
          this.gameComplete = doc.data().games.game2.gameComplete;
          this.missions = doc.data().games.game2.missions;
          this.selectedMission = doc.data().games.game2.selectedMission;
        } else {
          console.log("Save not exist!");
        }
      }
    },
    async updateUserData() {
      const gameData = {
        name: 'Odkryj Karpacz - dawni mieszkańcy',
        url: '/game-2',
        active: true,
        pass: "Przestajemy istnieć kiedy popadamy w zapomnienie",
        instructionArray: this.instructionArray,
        allMissionsComplete: this.allMissionsComplete,
        gameComplete: this.gameComplete,
        missions: this.missions,
        selectedMission: this.selectedMission,
        startDateTime: this.startDateTime,
      };

      const db = firebase.firestore();
      await db.collection('users').doc(this.$store.state.currentUser.uid).update({
        'games.game2': gameData
      });
    },
    async startGame() {
      this.isGameStart = true;
      this.selectedMission = 0;

      this.startDateTime = new Date();

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game2.startDateTime": this.startDateTime,
      });

      setInterval(() => {
        this.setTimer(this.startDateTime);
      }, 1000);
    },
    returnGame() {
      this.isGameStart = true;

      this.setTimer(this.startDateTime.toDate());
      setInterval(() => {
        this.setTimer(this.startDateTime.toDate());
      }, 1000);
    },
    selectMission(index) {
      this.isSelectedMission = true;
      this.selectedMission = index;
    },
    // selectAnswer(index) {
    //   if (this.selectedMission >= 0) {
    //     const isCorrect = (index === this.missions[this.selectedMission].questions[this.currentQuestion].correctAnswer) ? true : false;
    //     console.log(this.missions[this.selectedMission].questions[this.currentQuestion].answers[index], isCorrect);

    //     if (isCorrect) {
    //         this.instructionArray.push(this.missions[this.selectedMission].questions[this.currentQuestion].answers[index]);
    //         this.questionIsDone = true;
    //     }
    //   }
    // },
    backToMap() {
      this.isSelectedMission = false;
      this.selectedMission = null;
      this.currentQuestion = 0; // wyzerowanie licznika pytań
    },
    nextQuestion() {
      if (this.selectedMission >= 0) {
        this.currentQuestion++;
        // this.questionIsDone = false;
      }
    },
    prevQuestion() {
      if (this.selectedMission >= 0) {
        this.currentQuestion--;
        // this.questionIsDone = false;
      }
    },
    winMission() {
      this.isSelectedMission = false; // powrót do listy misji
      this.currentQuestion = 0; // wyzerowanie licznika pytań
      this.missions[this.selectedMission].isComplete = true; // ustawienie misji jako wykonana

      let missionComplete = 0;

      this.missions.forEach((mission) => {
        console.log(mission);
        if (mission.isComplete) missionComplete++;
      });
      if (missionComplete == this.missions.length) this.allMissionsComplete = true;

      this.updateUserData();
    },
    async addToPassword(item) {
      if (this.password.length != this.secretPassword.length) {
        this.password += item;
        this.selectedLettersCounter.push(item.length);
        // PRZESTAJEMY ISTNIEĆ KIEDY POPADAMY W ZAPOMNIENIE
        
        this.password = this.password.replace("PRZESTAJEMY ", "PRZESTAJEMY ");
        if (this.password.search("PRZESTAJEMY ") == -1) this.password = this.password.replace("PRZESTAJEMY", "PRZESTAJEMY ");
        
        this.password = this.password.replace("ISTNIEĆ ", "ISTNIEĆ ");
        if (this.password.search("ISTNIEĆ ") == -1) this.password = this.password.replace("ISTNIEĆ", "ISTNIEĆ ");
        
        this.password = this.password.replace("KIEDY ", "KIEDY ");
        if (this.password.search("KIEDY ") == -1) this.password = this.password.replace("KIEDY", "KIEDY ");
        
        this.password = this.password.replace("POPADAMY ", "POPADAMY ");
        if (this.password.search("POPADAMY ") == -1) this.password = this.password.replace("POPADAMY", "POPADAMY ");
        
        this.password = this.password.replace("POPADAMY W ", "POPADAMY W ");
        if (this.password.search("POPADAMY W ") == -1) this.password = this.password.replace("POPADAMY W", "POPADAMY W ");
      }
      if (this.password == this.secretPassword && this.password.length == this.secretPassword.length) {
        this.gameComplete = true;
        
        let endDateTime = new Date();

        const gameData = {
          name: 'Odkryj Karpacz - dawni mieszkańcy',
          url: '/game-2',
          active: true,
          pass: "Przestajemy istnieć kiedy popadamy w zapomnienie",
          instructionArray: this.instructionArray,
          allMissionsComplete: this.allMissionsComplete,
          gameComplete: this.gameComplete,
          missions: this.missions,
          selectedMission: this.selectedMission,
          startDateTime: this.startDateTime,
          endDateTime: endDateTime
        };

        const db = firebase.firestore();
        await db.collection('users').doc(this.$store.state.currentUser.uid).update({
          'games.game2': gameData
        });
      }
      if (this.password != this.secretPassword && this.password.length == this.secretPassword.length) {
        this.wrongPassword = true;
      }
    },
    resetPassword() {
      //this.password = "";
      this.password = this.password.slice(0, -this.selectedLettersCounter.pop());
      this.gameComplete = false;
      this.wrongPassword = false;
    },
    missionIsDisabled(index) {
      if (this.missions[index].isComplete) return true;
      // if (index > 0 && !this.missions[index-1].isComplete) return true;
      console.log(index);
      return false;
    },
    shufflePhotos() {
      this.missions.forEach((mission) => {
        mission.questions[1].photos.sort(() => Math.random() - 0.5);
      });
    },
    setTimer(startTime) {
      let now = new Date();
      let diffDate = now - startTime.getTime();

      let ms = diffDate % 1000;
      diffDate = (diffDate - ms) / 1000;
      let sec = diffDate % 60;
      diffDate = (diffDate - sec) / 60;
      let min = diffDate % 60;
      let hrs = (diffDate - min) / 60;

      if (sec < 10) sec = "0" + sec;
      if (min < 10) min = "0" + min;
      if (hrs < 10) hrs = "0" + hrs;

      this.userTimer = hrs + ":" + min + ":" + sec;
    },
  },
  computed: {
    user() {
      if (this.$store.state.currentUser)
        return this.$store.state.currentUser;
      else
        return { displayName: "" }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanalei+Fill&display=swap');

@font-face {
  font-family: fourHand_TRIAL;
  src: url('/game2/fourHand_TRIAL.otf') format('opentype');
}

#game-2 {
  /* background: #0A1032; */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

button {
  all: unset;
  background-image: url(/game2/btn_2.png);
  background-size: 100% 95%;
  background-repeat: no-repeat;
  width: 280px;
  height: 80px;
  font-size: 2rem;
  color: #ff7500;
  text-shadow: 1px 1px 2px #000;
  margin: 0 auto;
  font-family: 'Hanalei Fill', cursive;
  transition: .3s all;
}
button:disabled {
  /* opacity: 0.5; */
  filter: grayscale(1);
  opacity: 1;
}
/* button:hover { */
  /* animation: pushButton 0.3s linear; */
  /* background-image: url(/game2/btn_2.png); */
/* } */
button:active {
  transform: scale(0.95);
  background-image: url(/game2/btn_2.png);
}

header {
  width: 100%;
  height: 50px;
  background: #6d3e00;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  z-index: 10;
}
header .back-btn a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}
/* footer {
  width: 100%;
  height: 50px;
  background: red;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.menu-screen {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  background-image: url('/game2/map.jpg');
  background-size: auto 165%;
  background-position: center -5px;
  font-family: 'Pangolin', cursive;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
}
.menu-missions {
  max-width: 375px;
  margin: 0 auto;
}
.menu-missions p {
  line-height: 2rem;
  font-size: 1.5rem;
  color: rgb(92, 41, 0);
}
.menu-missions > div {
  transform: scale(0.9);
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
}
.menu-missions button {
  all: unset;
  width: 55px;
  height: 60px;
  position: relative;
  background-image:url('/game2/stones/1+2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.menu-missions button::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image:url('/game2/x.png');
  bottom: -30px;
  left: -10px;
}
.menu-missions button[active="true"]::after {
  background-image:url('/game2/done.png');
}
.rose-btn:disabled {
  filter: grayscale(1);
  opacity: 1;
}
.game-screen {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  font-family: 'Pangolin', cursive;
  background-image: url('/game2/bg-papirus.jpg');
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card {
  /* background-image: url('/game2/card.png');
  background-size: cover;
  height: 360px;
  width: 255px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 10px 10px 3px 0 rgba(66, 30, 0, 0.7);
  border-radius: 1rem;
  color: #fff;
  font-size: 1.5rem; */
  margin: 1rem;
  color: rgb(92, 41, 0);
  background: #eeeeee7d;
  padding: 1rem 0.5rem;
  border: 4px dashed black;
}
.card h4 {
  margin-bottom: 1rem;
  line-height: 2rem;
  font-size: 1.3rem;
  letter-spacing: -1px;
}
.card p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: normal;
}
.card img {
  margin: 0px;
  border: 2px solid;
}
.card label {
  font-family: fourHand_TRIAL;
  font-size: 2rem;
  line-height: 0;
}
#intro-screen {
  background-image: url("/game2/bg-papirus2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 50px);
  padding: 2rem 1rem;
  margin-top: 50px;
  font-family: 'Pangolin', cursive;
}
#intro-screen p {
  text-align: justify;
  margin: 0 1rem;
  line-height: 2rem;
  font-size: 1.5rem;
  color: rgb(92, 41, 0);
}

.intro-pikto {
  display: flex;
  justify-content: center;
}

.intro-pikto img {
  height: 100px;
  width: auto;
}
/* .content {
  margin: 50px 0;
  padding: 1rem;
} */
.progress-bar {
  display: flex;
  justify-content: space-evenly;
  /* margin: 1rem 2rem; */
  /* padding: 0.5rem; */
  /* border-radius: 0.5rem;
  background: rgba(66, 30, 0, 0.7);
  border: 2px solid rgb(255, 202, 156); */
}
.progress-bar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #111;
  margin: 0;
  box-shadow: 2px 7px 10px;
  opacity: 0.5;
  transform: scale(0.8);
  transition: .3s all;
}
.active-item {
  opacity: 1 !important;
  transform: scale(1) !important;
  /* font-weight: bold; */
  /* border: 3px solid !important; */
}
img {
  margin: 1rem 0;
  width: 100%;
}
.rose-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pangolin', cursive;
  background-image: url("/game2/bg-papirus2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-top: 50px;
  height: calc(100vh - 50px);
  padding: 1rem 0 2rem 0;
}
.rose-screen > p {
  margin-bottom: 1rem;
  line-height: 2rem;
  font-size: 1.5rem;
  color: rgb(92, 41, 0);
}
.rose-grid {
  display: grid;
  grid-template-columns: repeat(7, 35px);
  grid-template-rows: repeat(7, 35px);
  gap: .5rem;
  
}
.rose-grid button {
  all: unset;
  border-bottom: 3px solid;
  background: rgb(66 30 0 / 23%);
  border-radius: 10px;
  font-weight: bold;
  text-shadow: 2px 3px 4px saddlebrown;
  /* transition: .3s all; */
}
.rose-grid button:active {
  border-bottom: 1px solid;
  position: relative;
  top: 2px;
}
.rose {
  grid-column: 2/7;
  grid-row: 2/7;
}
.secret-password,
.placeholder-password {
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  height: 20px;
  width: 250px;
  margin-top: 4px;
}
.password-pass {
  color: green;
}
.password-wrong {
  color: red;
}
.rose-screen-btns {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 100%;
}
.rose-screen-btns button {
  width: 150px;
  margin: 0;
  color: #ff7500;
}
.answer-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
</style>